import { TabBar } from 'client/shared/components/base/tab-bar';
import {
  BenchmarkFilter,
  TrackVariableWithDistributions,
  VisualizationType,
  wrap,
} from 'core';
import React from 'react';
import './styles.scss';
import {
  EmbedDataPointContext,
  Visualization,
} from 'client/shared/components/visualization-picker';

interface Props {
  readonly trackVariable: TrackVariableWithDistributions | null;
  readonly ideasCopy: Record<string, React.ReactNode>;
  readonly fipsCode: string;
}

enum ReportCardTabBarKey {
  DATA_DETAILS = 'DATA_DETAILS',
  IDEAS = 'IDEAS',
}

const tabBarOptions = [
  {
    key: ReportCardTabBarKey.DATA_DETAILS,
    label: 'Data Details',
  },
  {
    key: ReportCardTabBarKey.IDEAS,
    label: 'Ideas from Grade "A" Communities',
  },
];

export const ReportCardTabBar: React.FC<Props> = (p) => {
  const { trackVariable, ideasCopy, fipsCode } = p;
  const [selectedTabBar, setSelectedTabBar] = React.useState<ReportCardTabBarKey>(
    ReportCardTabBarKey.DATA_DETAILS
  );
  return (
    <div>
      <TabBar<ReportCardTabBarKey>
        selected={
          tabBarOptions.find((option) => option.key === selectedTabBar) ??
          tabBarOptions[0]
        }
        tabSelected={(tab) => {
          setSelectedTabBar(tab.key);
        }}
        tabs={tabBarOptions}
      />
      {wrap(() => {
        switch (selectedTabBar) {
          case ReportCardTabBarKey.DATA_DETAILS:
            return (
              <div>
                {!!trackVariable && (
                  <Visualization
                    baseFips={fipsCode}
                    benchmarkFilter={BenchmarkFilter.DEFAULT}
                    embedContext={EmbedDataPointContext.CONTENT_POST}
                    expanded
                    mapData={{ fipsAreasShapes: {}, mapBoundCoordinates: null }}
                    publishingEntityId=""
                    trackVariable={trackVariable}
                    visualizationType={VisualizationType.LINE}
                  />
                )}
              </div>
            );
          case ReportCardTabBarKey.IDEAS:
            return ideasCopy[trackVariable?.name ?? ''];
        }
      })}
    </div>
  );
};
ReportCardTabBar.displayName = 'ReportCardTabBar';
