import * as React from 'react';
import { WordCloud } from 'client/shared/components/word-cloud';
import { getWordCounts } from 'core';
import { VotingProps } from 'client/respondent/core/types';
import { cloneDeep } from 'lodash';
import { animated, useSpring } from 'react-spring';
import { RESULT_COPY } from './copy';
import { LoadableComment } from 'client/shared/core/types';

export interface FreeTextResultProps {
  readonly prevVote: VotingProps.Vote_FreeText | null;
  readonly comments: LoadableComment<readonly VotingProps.Comment[]>;
  readonly minCommentsForWordCloud: number;
  readonly animate: boolean;
}

export const FreeTextResult: React.FC<FreeTextResultProps> = (props) => {
  const wordCloudAnimation = useSpring({
    to: { opacity: 1, transform: 'scale(1)' },
    from: { opacity: 0, transform: 'scale(0.1)' },
    delay: 900,
  });
  if (props.comments.loadedQuestion.length < props.minCommentsForWordCloud) {
    return (
      <>
        {props.prevVote?.comment && (
          <PrevResponse comment={props.prevVote.comment.comment} />
        )}
        <NotEnoughComments />
      </>
    );
  }
  const comments = props.comments.loadedQuestion.map((c) => c.comment);
  const cloud = (
    <WordCloud
      fontSizes={[12, 26]}
      height={240}
      maxWords={30}
      width={680}
      words={getWordCounts(cloneDeep(comments))}
    />
  );
  return (
    <div className="ml-2">
      {props.prevVote?.comment && (
        <PrevResponse comment={props.prevVote.comment.comment} />
      )}
      <p className="pt-4 font-italic">{RESULT_COPY.overallResults}</p>
      {props.animate ? (
        <animated.div style={wordCloudAnimation}>{cloud}</animated.div>
      ) : (
        cloud
      )}
    </div>
  );
};

const NotEnoughComments: React.FC = () => {
  return (
    <div className="pt-4">
      <p className="font-italic">{RESULT_COPY.overallResults}</p>
      {RESULT_COPY.notEnoughResults}
    </div>
  );
};

const PrevResponse: React.FC<{ readonly comment: string }> = (props) => {
  return (
    <div className="pt-4">
      <p className="font-italic">{RESULT_COPY.youResponded}</p>
      {props.comment}
    </div>
  );
};
